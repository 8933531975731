<template>
  <main class="page">
    <div class="page__grid">
      <h1 class="page__header">You are done!</h1>

      <p class="page__description">
        Congratulations! you’ve written the most meaningful letter of your life.
        You’ve held nothing back, and you’ve made sense of everything. You can
        revise your letter or begin a new one. Most users subscribe to our short
        video activation series – that shows how to build, sustain, and transfer
        multigenerational family wealth.
      </p>
      <div class="step">
        <div class="step__btn">
          <router-link :to="{ name: 'Index' }" class="button button--primary"
            >Start Over</router-link
          >
          <a href="#" class="button button--secondary">Video series</a>
        </div>
      </div>
    </div>

    <div class="page__grid">
      <img
        src="@/assets/img/success.svg"
        class="page__grid--image"
        alt="Legacy Composer Home"
      />
    </div>
  </main>
</template>

<script>
export default {
  name: 'Success',
}
</script>
